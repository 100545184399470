import styled from "styled-components";
import { primary } from "../../utils/colors";

export const Wrapper = styled.div`
  &:hover {
    cursor: pointer;
  }
  .faq {
    border-bottom: 2px solid ${primary};
  }
  .faq-title {
    border: none;
    background: none;
    padding: 0;
    outline: none;
    width: 100%;
    text-align: left;
    cursor: pointer;
    font-family: "Rubik", sans-serif;
    font-weight: bold;
    font-size: 1.2rem;
    padding: 30px;
    padding-right: 72px;
    padding-left: 16px;
    position: relative;
    &::after {
      content: "";
      display: block;
      width: 10px;
      height: 10px;
      border-left: 2px solid ${primary};
      border-bottom: 2px solid ${primary};
      position: absolute;
      top: 34px;
      right: 36px;
      transform: rotate(-45deg);
      transition: transform 0.3s ease-in-out;
    }
    &.active {
      &::after {
        transform: rotate(135deg);
      }
    }
  }
  .faq-content {
    padding: 16px 16px 72px 16px;
    font-size: 1rem;
    color: ${primary};
    p {
      line-height: 1.8;
    }
  }
`;
