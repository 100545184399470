import React from "react";
import PropTypes from "prop-types";
import "./FlatIllustration.css";

const FlatIllustration = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="500"
      height="650"
      viewBox="0 0 500 650"
    >
      <path
        opacity="0.5"
        fill="#9DA3B1"
        d="M203.331,586.893l286.89-164.424 c8.58-4.937,8.697-17.277,0.117-22.331l-180.879-105.19c-3.996-2.35-8.933-2.35-12.929,0L10.465,458.903 c-8.581,4.935-8.698,17.275-0.118,22.328l180.055,105.66C194.398,589.242,199.334,589.242,203.331,586.893z"
        id="Shadow"
      />
      <g id="Base">
        <path
          fill="#9DA3B1"
          d="M10.229,433.888l180.525,105.778c3.879,2.232,8.697,2.351,12.576,0.116l287.007-164.659 c3.879-2.232,6.347-6.347,6.347-10.812v-19.038L4,403.727l0.118,19.468C4.118,427.659,6.469,431.655,10.229,433.888z"
        />
        <path
          fill="#DADFEB"
          d="M203.331,520.978l286.89-164.424c8.58-4.936,8.697-17.276,0.117-22.33l-180.995-105.19 c-3.997-2.351-8.934-2.351-12.929,0L10.464,392.988c-8.58,4.935-8.697,17.276-0.117,22.329l180.055,105.659 C194.398,523.21,199.334,523.21,203.331,520.978z"
        />
      </g>
      <g id="Screen">
        <path
          opacity="0.7"
          fill="#FFF"
          d="M204.153,505.817l267.027-154.436l-0.117-6.229L33.58,401.753 v6.631L204.153,505.817z"
        />
        <path
          fill="#FFF"
          d="M204.036,499.587l267.028-154.435l-169.009-98.488L33.58,401.753L204.036,499.587z"
        />
        <g opacity="0.5" fill="#9DA3B1">
          <path d="M71.58,398.042l45.249-25.621c2.351-1.294,5.17-1.294,7.522,0.117l22.801,13.516 c1.411,0.823,1.411,2.821,0,3.644l-44.896,25.739c-2.351,1.292-5.172,1.292-7.522,0L71.579,401.92 C70.17,400.862,70.17,398.864,71.58,398.042z" />
          <path d="M271.851,367.13l33.261-19.156c2.35-1.292,5.17-1.292,7.522,0.117l22.8,13.399 c1.41,0.822,1.41,2.82,0,3.644l-32.907,19.273c-2.351,1.293-5.172,1.293-7.522,0l-23.154-13.516 C270.557,369.951,270.557,367.954,271.851,367.13z" />
          <path d="M116.359,424.016l45.248-25.738c2.352-1.294,5.171-1.294,7.522,0l67.462,39.489 c1.411,0.822,1.411,2.821,0,3.643l-45.013,25.856c-2.351,1.293-5.172,1.293-7.523,0l-67.813-39.606 C114.949,426.837,114.949,424.839,116.359,424.016z" />
          <path d="M144.801,356.436l94.965-54.415c1.292-0.706,2.938-0.706,4.229,0.235c2.234,1.644,1.999,5.052-0.352,6.463 l-94.846,54.534c-1.292,0.704-2.938,0.704-4.23-0.236l-0.118-0.118C142.215,361.255,142.333,357.846,144.801,356.436z" />
          <path d="M161.843,366.309l79.215-45.603c1.292-0.704,2.937-0.704,4.23,0.236c2.234,1.645,1.998,5.054-0.352,6.464 l-79.098,45.719c-1.292,0.705-2.938,0.705-4.231-0.235l-0.117-0.116C159.14,371.126,159.375,367.719,161.843,366.309z" />
          <path d="M240.47,341.039l19.157-10.812c1.293-0.707,2.938-0.707,4.23,0.234c2.233,1.646,1.997,5.054-0.352,6.463 l-19.04,10.931c-1.292,0.705-2.938,0.705-4.23-0.235l-0.117-0.117C237.884,345.858,238.12,342.45,240.47,341.039z" />
          <path d="M178.767,376.181l48.657-27.385c1.293-0.706,2.938-0.706,4.231,0.235c2.232,1.646,1.998,5.053-0.353,6.463 l-48.423,27.502c-1.292,0.705-2.938,0.705-4.23-0.235l-0.117-0.117C176.181,381.001,176.298,377.591,178.767,376.181z" />
          <path d="M195.809,386.053l84.856-48.891c1.292-0.706,2.938-0.706,4.23,0.234c2.233,1.646,1.998,5.054-0.352,6.464 l-84.739,49.01c-1.293,0.705-2.938,0.705-4.23-0.235l-0.118-0.118C193.223,390.873,193.341,387.463,195.809,386.053z" />
          <path d="M264.444,287.329l44.663-25.388c2.232-1.291,4.937-1.291,7.168,0l94.73,55.71 c1.762,1.057,1.762,3.524,0,4.465l-44.31,25.388c-2.232,1.292-4.936,1.292-7.169,0l-94.964-55.828 C262.683,290.854,262.683,288.386,264.444,287.329z" />
        </g>
      </g>
      <g id="Buttons">
        <path
          fill="#B50016"
          d="M28.149,397.186c1.125,1.375,0.271,3.207-1.908,4.093c-2.179,0.888-4.857,0.493-5.983-0.881 c-1.125-1.375-0.271-3.207,1.908-4.093C24.345,395.417,27.023,395.812,28.149,397.186z"
        />
        <path
          fill="#B50016"
          d="M19.762,397.016v2.27l8.883-0.987v-1.974L19.762,397.016z"
        />
        <path
          fill="#FF0A27"
          d="M28.149,395.212c1.125,1.374,0.271,3.207-1.908,4.093c-2.179,0.888-4.857,0.493-5.983-0.881 c-1.125-1.375-0.271-3.207,1.908-4.093C24.345,393.443,27.023,393.838,28.149,395.212z"
        />
        <path
          fill="#F9A825"
          d="M37.032,391.264c1.125,1.375,0.271,3.207-1.908,4.093c-2.179,0.888-4.857,0.493-5.983-0.881 c-1.125-1.375-0.271-3.207,1.908-4.093C33.228,389.495,35.906,389.89,37.032,391.264z"
        />
        <path
          fill="#F9A825"
          d="M28.645,391.094v2.27l8.883-0.987v-1.974L28.645,391.094z"
        />
        <path
          fill="#FDD835"
          d="M37.032,389.29c1.125,1.374,0.271,3.207-1.908,4.093c-2.179,0.888-4.857,0.493-5.983-0.881 c-1.125-1.375-0.271-3.207,1.908-4.093C33.228,387.521,35.906,387.916,37.032,389.29z"
        />
        <path
          fill="#2E7D32"
          d="M45.915,385.342c1.125,1.375,0.271,3.207-1.908,4.093c-2.179,0.888-4.857,0.493-5.983-0.881 c-1.125-1.375-0.271-3.207,1.908-4.093C42.111,383.573,44.789,383.968,45.915,385.342z"
        />
        <path
          fill="#2E7D32"
          d="M37.528,385.172v2.27l8.883-0.987v-1.974L37.528,385.172z"
        />
        <path
          fill="#4CAF50"
          d="M45.915,383.368c1.125,1.374,0.271,3.207-1.908,4.093c-2.179,0.888-4.857,0.493-5.983-0.881 c-1.125-1.375-0.271-3.207,1.908-4.093C42.111,381.6,44.789,381.994,45.915,383.368z"
        />
      </g>
      <g id="Gaze_Replay" fill="#2E2B2B">
        <path
          opacity="0.7"
          d="M301.783,191.523L33.58,347.015l170.183,97.433L470.79,290.014 L301.783,191.523z M222.686,318.456c-3.056,1.879-7.523,3.056-11.283,3.056c-19.627,1.291-33.731,10.812-33.143,22.33 c0.587,4.467-2.233,8.933-7.757,12.34c-12.105,7.17-33.261,6.347-47.599-1.762c-14.339-8.109-16.102-20.45-3.996-27.621 c6.346-3.76,15.632-5.642,24.329-4.817c20.215,1.176,36.904-5.877,40.782-17.042c0.352-1.88,2.233-3.997,4.82-5.523 c7.874-4.702,21.978-4.231,31.38,1.058C229.384,305.762,230.677,313.872,222.686,318.456z"
        />
        <path
          opacity="0.9"
          d="M203.646,444.447L33.58,347.015v7.64l170.183,97.314 L470.79,297.535l-0.116-7.64L203.646,444.447z"
        />
      </g>
      <g id="Gaze_Plot">
        <path
          opacity="0.5"
          fill="#DADFEB"
          d="M203.763,435.567L470.79,281.133l-169.007-98.49L33.58,338.252 L203.763,435.567z"
        />
        <path
          fill="#DADFEB"
          d="M203.646,443.911L470.32,289.478v-8.345L203.646,435.567L33.698,338.252l-0.118,8.228L203.646,443.911z"
        />
        <path
          id="gazeplot_path"
          fill="#2E4C99"
          d="M306.64,212.025c-5.173,3.174-5.407,8.462-1.294,13.045l-65.228,23.389 c-6.347-3.761-15.278-4.465-20.215-1.526c-4.937,3.056-3.879,8.696,2.469,12.575c1.645,1.058,3.525,1.763,5.406,2.351l-1.646,29.5 c-10.344-1.292-20.333,0.117-27.15,4.231c-11.165,6.7-10.342,18.57,0.94,28.09l-38.549,17.394 c-10.343-6.229-25.033-7.286-33.025-2.467c-8.109,4.937-6.348,14.104,3.996,20.567c10.341,6.464,25.386,7.64,33.496,2.702 c6.934-4.111,6.699-11.399,0.118-17.393l38.548-17.396c0.236,0.118,0.471,0.354,0.706,0.472 c16.102,9.989,39.372,11.869,52.065,4.231c12.693-7.64,9.872-21.979-6.229-31.969c-5.523-3.408-11.987-5.877-18.569-7.404 l1.646-29.501c3.291,0.118,6.464-0.471,8.815-1.762c3.643-2.233,3.995-5.877,1.409-9.284l65.229-23.389 c0.117,0.118,0.235,0.235,0.471,0.352c8.579,5.29,20.921,6.348,27.618,2.233c6.7-3.996,5.173-11.636-3.29-16.924 C325.679,208.97,313.339,208.029,306.64,212.025z"
        />
        <path
          id="gazeplot_path"
          fill="#4C7EFF"
          d="M306.64,207.912c-5.173,3.173-5.407,8.463-1.294,13.045l-65.228,23.391 c-6.347-3.762-15.278-4.467-20.215-1.529c-4.937,3.056-3.879,8.698,2.469,12.577c1.645,1.057,3.525,1.762,5.406,2.35l-1.646,29.501 c-10.344-1.293-20.333,0.118-27.15,4.231c-11.165,6.699-10.342,18.568,0.94,28.089l-38.549,17.396 c-10.343-6.229-25.033-7.289-33.025-2.47c-8.109,4.937-6.348,14.105,3.996,20.567c10.341,6.466,25.386,7.642,33.496,2.705 c6.934-4.114,6.699-11.4,0.118-17.395l38.548-17.394c0.236,0.116,0.471,0.352,0.706,0.469c16.102,9.99,39.372,11.87,52.065,4.232 c12.693-7.641,9.872-21.979-6.229-31.969c-5.523-3.408-11.987-5.878-18.569-7.404l1.646-29.5c3.291,0.117,6.464-0.47,8.815-1.764 c3.643-2.232,3.995-5.876,1.409-9.283l65.229-23.389c0.117,0.117,0.235,0.235,0.471,0.353c8.579,5.288,20.921,6.346,27.618,2.232 c6.7-3.996,5.173-11.636-3.29-16.924C325.679,204.856,313.339,203.799,306.64,207.912z"
        />
        <path
          id="gazeplot_path"
          fill="#FFF"
          d="M155.496,350.122l-2.233,1.763l-10.812-8.227c0.117,1.409-0.118,2.703-0.822,3.877l-2.586-1.996 c0.352-0.587,0.587-1.527,0.587-2.586c0-1.057-0.353-1.997-0.941-2.938l1.881-1.411L155.496,350.122z"
        />
        <path
          id="gazeplot_path"
          fill="#FFF"
          d="M236.827,307.577l3.056,2.351l-9.168,7.052c-1.058-0.94-1.88-1.997-2.351-3.173 c-0.47-1.176-0.94-2.938-1.174-5.406c-0.235-1.998-0.47-3.174-0.705-3.763c-0.353-0.821-0.823-1.41-1.528-1.997 c-0.706-0.588-1.41-0.822-2.233-0.939c-0.705-0.117-1.293,0.117-1.88,0.587c-0.588,0.47-0.823,0.939-0.706,1.527 s0.588,1.176,1.528,1.998l-2.938,1.763c-1.645-1.528-2.468-2.82-2.468-4.114c0-1.174,0.706-2.35,1.881-3.29 c1.41-1.058,2.821-1.527,4.583-1.409c1.646,0.117,3.173,0.703,4.583,1.762c0.822,0.587,1.41,1.175,1.88,1.881 c0.47,0.704,0.823,1.527,1.175,2.467c0.235,0.587,0.352,1.646,0.47,2.939c0.118,1.409,0.235,2.231,0.353,2.585 c0.118,0.353,0.236,0.821,0.353,1.057L236.827,307.577z"
        />
        <path
          id="gazeplot_path"
          fill="#FFF"
          d="M231.185,253.514l1.058-1.059c0.471,0.235,0.823,0.471,1.292,0.471c0.353,0,0.706,0,0.941-0.235 s0.352-0.47,0.352-0.821c-0.117-0.353-0.352-0.705-0.822-1.059c-0.471-0.353-0.823-0.47-1.292-0.586c-0.471,0-0.706,0-1.058,0.234 c-0.118,0.118-0.353,0.352-0.471,0.587l-1.175-1.175c0.353-0.235,0.587-0.587,0.47-0.822c0-0.353-0.235-0.588-0.588-0.823 c-0.235-0.234-0.587-0.353-0.939-0.353c-0.353,0-0.588,0-0.823,0.235c-0.235,0.118-0.353,0.353-0.235,0.706 c0,0.234,0.235,0.588,0.588,0.821l-1.411,0.705c-0.47-0.47-0.705-0.822-0.822-1.292c-0.118-0.353-0.118-0.705,0-1.058 c0.118-0.354,0.352-0.705,0.705-0.94c0.588-0.47,1.411-0.705,2.35-0.587c0.706,0.118,1.41,0.353,1.881,0.706 c0.704,0.586,1.057,1.291,0.822,2.114c0.471-0.234,1.059-0.234,1.646-0.117c0.588,0.117,1.175,0.353,1.646,0.706 c0.705,0.587,1.176,1.175,1.293,1.998c0.118,0.706-0.235,1.409-0.823,1.88c-0.587,0.47-1.411,0.705-2.234,0.705 C232.712,254.456,232.007,253.984,231.185,253.514z"
        />
        <path
          id="gazeplot_path"
          fill="#FFF"
          d="M327.56,221.78l-2.703-1.997l-4.349,3.29l-2.232-1.646l-3.878-9.755l1.646-1.291l8.344,6.346l1.292-0.939 l2.232,1.645l-1.292,0.94l2.702,1.998L327.56,221.78z M322.624,218.137l-4.468-3.407l2.115,5.288L322.624,218.137z"
        />
      </g>
      <g id="Heat_Map">
        <g fill="#DADFEB">
          <path d="M203.646,436.016L470.32,281.464v-8.345L203.763,427.671L33.815,330.239l-0.235,8.344L203.646,436.016z" />
          <path
            opacity="0.5"
            d="M204.116,427.671l267.027-154.552l-169.007-98.372 L33.933,330.239L204.116,427.671z"
          />
        </g>
        <path
          id="heatmap_element_1"
          fill="#2E4C99"
          d="M345.033,280.289c-6.229,2.232-13.985,3.172-21.86,2.468c-10.812-0.94-21.625,0.821-28.676,5.522 c-11.988,7.875-9.169,20.922,6.346,29.029c15.514,8.111,37.962,8.346,49.951,0.472c0.821-0.587,1.292-1.058,2.349-1.646 c3.527-3.408,10.461-5.993,17.865-6.816c4.701-0.588,9.05-1.881,12.458-4.114c9.169-5.992,7.053-15.983-4.817-22.213 C368.305,277.702,354.553,276.762,345.033,280.289z"
        />
        <path
          id="heatmap_element_1"
          fill="#4C7EFF"
          d="M345.033,275.94c-6.229,2.232-13.985,3.173-21.86,2.468c-10.812-0.94-21.625,0.821-28.676,5.523 c-11.988,7.874-9.169,20.919,6.346,29.03c15.514,8.108,37.962,8.344,49.951,0.471c0.821-0.588,1.292-1.059,2.349-1.646 c3.527-3.408,10.461-5.993,17.865-6.816c4.701-0.588,9.05-1.881,12.458-4.114c9.169-5.993,7.053-15.983-4.817-22.212 C368.305,273.237,354.553,272.414,345.033,275.94z"
        />
        <path
          id="heatmap_element_1"
          fill="#FFE24C"
          d="M344.212,280.406c-1.058,0.704-1.882,1.41-2.468,2.233c-1.881,2.702-7.053,3.878-12.342,2.819 c-8.463-1.645-17.629-0.587-23.271,3.056c-7.993,5.29-6.111,13.985,4.23,19.393c10.344,5.407,25.271,5.643,33.379,0.353 c2.704-1.763,4.231-3.996,4.818-6.229c0.706-2.938,4.937-4.937,10.226-4.701c4.818,0.235,9.402-0.705,12.575-2.703 c5.878-3.878,4.467-10.106-3.055-14.104C360.9,276.646,350.088,276.646,344.212,280.406z"
        />
        <path
          id="heatmap_element_1"
          fill="#FFB74C"
          d="M338.298,288.916c-2.561,0.742-4.866,0.975-6.78,0.736c-0.653-0.11-1.497-0.112-2.341-0.114 c-5.822,0.188-12.664,4-15.213,8.509c-2.552,4.51-0.079,7.59,5.7,7.307c2.392-0.04,4.864-0.976,7.162-2.297l-0.097,0.055 c3.168,0.611,5.178-0.546,7.093-1.646c5.71,0.364,10.898-3.105,13.143-6.951c2.15-3.793,0.141-6.652-4.839-6.463 C340.812,288.077,339.673,288.49,338.298,288.916z"
        />
        <path
          id="heatmap_element_1"
          fill="#FF4D4D"
          d="M338.003,292.265c-2.508,0.546-3.984,0.587-5.938-0.085c-0.693-0.141-0.819-0.247-1.386-0.281 c-2.292-0.026-4.855,1.447-5.78,3.236c-0.926,1.789,0.096,3.356,2.325,3.42c0.881,0.032,1.727-0.186,2.635-0.617 c1.938-0.935,4.448-1.301,6.304-1.023l0.062-0.036c0.096,0.036,0.251,0.036,0.407,0.034c1.666,0.03,3.541-1.049,4.22-2.337 c0.647-1.358-0.087-2.463-1.752-2.493C338.786,292.084,338.032,292.158,338.003,292.265z"
        />
        <path
          id="heatmap_element_2"
          fill="#2E4C99"
          d="M173.088,299.094c-7.641,2.82-17.042,3.878-26.796,2.938c-13.281-1.176-26.445,1.058-35.142,6.699 c-14.691,9.637-11.283,25.504,7.757,35.494c19.04,9.99,46.307,10.226,61.116,0.587c1.058-0.705,1.526-1.292,2.82-2.115 c4.349-4.231,12.812-7.287,21.861-8.345c5.759-0.704,11.165-2.351,15.161-5.054c11.282-7.404,8.579-19.509-5.876-27.149 C201.647,295.802,184.724,294.745,173.088,299.094z"
        />
        <path
          id="heatmap_element_2"
          fill="#4C7EFF"
          d="M173.088,294.862c-7.641,2.82-17.042,3.879-26.796,2.938c-13.281-1.176-26.445,1.057-35.142,6.698 c-14.691,9.639-11.283,25.506,7.757,35.494c19.04,9.99,46.307,10.226,61.116,0.587c1.058-0.706,1.526-1.292,2.82-2.115 c4.349-4.231,12.812-7.286,21.861-8.345c5.759-0.704,11.165-2.351,15.161-5.054c11.282-7.403,8.579-19.509-5.876-27.149 C201.647,291.688,184.724,290.632,173.088,294.862z"
        />
        <path
          id="heatmap_element_2"
          fill="#FFE24C"
          d="M172.03,300.386c-1.293,0.823-2.232,1.763-2.938,2.703c-2.351,3.291-8.697,4.701-15.043,3.525 c-10.342-1.997-21.507-0.704-28.441,3.762c-9.873,6.464-7.522,17.042,5.17,23.74c12.693,6.699,30.91,6.817,40.783,0.353 c3.291-2.114,5.172-4.816,5.877-7.638c0.823-3.643,6.111-5.994,12.576-5.643c5.876,0.236,11.517-0.821,15.396-3.407 c7.169-4.702,5.407-12.341-3.761-17.277C192.48,295.919,179.199,295.802,172.03,300.386z"
        />
        <path
          id="heatmap_element_2"
          fill="#FFB74C"
          d="M159.499,319.196c-2.987-0.698-5.378-1.793-7.27-3.188c-0.697-0.497-1.395-0.996-2.291-1.493 c-5.977-3.188-14.642-3.188-19.223-0.199c-4.682,2.986-3.585,7.471,2.39,10.657c2.49,1.295,5.378,1.792,8.267,1.792h-0.099 c2.888,1.993,5.577,1.993,7.966,1.993c5.578,3.984,12.451,3.388,16.434,0.797c3.984-2.589,3.087-6.475-1.992-9.164 C162.388,319.693,160.994,319.495,159.499,319.196z"
        />
        <path
          id="heatmap_element_2"
          fill="#FF4D4D"
          d="M156.312,322.581c-4.282-1.195-6.573-2.39-9.063-4.681c-0.896-0.697-1.095-0.995-1.992-1.395 c-3.487-1.792-8.466-1.891-11.155-0.1c-2.689,1.793-2.191,4.582,1.295,6.476c1.394,0.697,2.788,0.997,4.481,1.294 c3.686,0.3,7.768,1.694,10.558,3.486h0.1c0.2,0.101,0.399,0.299,0.597,0.399c2.59,1.294,6.174,1.394,8.167,0.099 c1.993-1.295,1.593-3.388-0.996-4.681C157.707,322.98,156.512,322.482,156.312,322.581z"
        />
      </g>
    </svg>
  );
};

export default FlatIllustration;
