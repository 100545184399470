import React from 'react';
import Img from 'gatsby-image';

import styled from 'styled-components';

import SectionLayout from './SectionLayout';

const StyledImg = styled(Img)`
  min-width: 1px;
  min-height: 400px;
  margin: 2em 0 3em 0;

  > img {
    height: 120%;
    width: 120%;
  }
  box-shadow: 0 23px 40px rgba(0, 0, 0, 0.2);

`;
class Demo extends React.Component {
  render() {
    const {image} = this.props;

    return (
      <SectionLayout isCentered>
        <div className="container">
        <div className="row">
            <div className="offset-md-3 col-md-6 text-center">
              <p>Demo goes here</p>
            </div>
          </div>
          <div className="row">
            <div className="offset-md-3 col-md-6 text-center">
              <StyledImg fluid={image}/>
            </div>
          </div>
        </div>

      </SectionLayout>
    );
  }
}

export default Demo;
