import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import CloseIcon from "../assets/close.svg";
import { StaticQuery } from "gatsby";

const Body = styled.p`
  font-size: 1rem;
  line-height: 1.8;
  color: #444;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 50%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Card = styled.div`
  box-shadow: 0 23px 40px rgba(0, 0, 0, 0.2);
  padding: 1.8rem;
  margin-top: 0;
  position: fixed;
  z-index: 1000;
  bottom: 15px;
  left: 15px;
  max-width: 550px;
  background-color: white;
  background-color: #8ec5fc;
  background-image: linear-gradient(62deg, #8ec5fc 0%, #e0c3fc 50%);
  background: linear-gradient(135deg, #3f97ff 0%, #62ffec 100%);
  background: linear-gradient(135deg, #aecaef 0%, #e1d7f2 100%);
  background-image: linear-gradient(to right, #b3ffab 0%, #12fff7 100%);
  background-image: linear-gradient(-45deg, #ffc796 0%, #ff6b95 100%);
  background-image: linear-gradient(to right, #7579ff 0%, #b224ef 100%);
  h1,
  p {
    align-text: center;
    color: white;
  }

  .close_banner {
    font-size: 1rem;
    position: absolute;
    right: 20px;
    top: 18px;
    background-color: #fff;
    padding: 0px 8px;
    border-radius: 7px;
    cursor: pointer;
    color: #ac2cf0;
    font-weight: bold;
  }

  @media (max-width: 768px) {
    .close_banner {
      right: 15px;
    }
  }

  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 2rem;
    margin: 2rem;

    & > div {
      margin-bottom: 3rem;
    }
  }
`;
class VisualEyesRedirect extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query HeadingQuery {
            espa: file(relativePath: { regex: "/espa.jpg/" }) {
              childImageSharp {
                fluid(maxWidth: 1400, quality: 80) {
                  srcSet
                  base64
                  src
                }
              }
            }
          }
        `}
        render={(data) => (
          <Card>
            <div>
              <div
                style={{ width: "100%", cursor: "pointer" }}
                onClick={() => {
                  window.open(
                    "https://drive.google.com/file/d/1ElBo47Qhpz2DxYFTa0bO_IyXjczVvBIJ/view?usp=sharing"
                  );
                }}
              >
                <Img
                  style={{ height: "50px", width: "100%" }}
                  imgStyle={{ objectFit: "contain" }}
                  fluid={data.espa.childImageSharp.fluid}
                />
              </div>
              <Column>
                <h1 className="title">AI Design Assistant</h1>

                <Body>
                  Want to optimise your designs faster? Use our{" "}
                  <b>AI generated Eye-Tracking insights</b>.
                </Body>
              </Column>
              <a
                href="https://www.visualeyes.design?ref=loceye.io"
                className="btn btn--sm btn--primary type--uppercase "
              >
                <span className="btn__text">Take me to VisualEyes</span>
              </a>
            </div>
            <span onClick={this.props.close} className="close_banner">
              x
            </span>
          </Card>
        )}
      />
    );
  }
}

export default VisualEyesRedirect;
