import React from "react";

const FAQS = [
  {
    title: "How eye tracking works",
    content: () => (
      <div class="accordion-custom-content">
        <p>
          Eye-tracking is an innovative behavioural method that allows you to
          track your customers' eye movements.
        </p>
        <p>
          A simple web-camera is watching the participant while he/she performs
          a pre-defined task by you. Our face-tracking algorithms can detect the
          position of the eyes and calculate where the participant is look at.
        </p>
        <p>
          The process begins with a calibration session, when our system is
          learning how participant's eyes moving while the participant is
          watching certain parts of the screen. After the successful
          calibration, we display your media and our algorithm starts to predict
          the exact position of the participant's look.
        </p>
        <p>
          <b>Bonus:</b> We discard 🗑 all the personal information from a session
          (e.g. participant's face) right the processing is ended.
        </p>
      </div>
    ),
  },
  {
    title: "How precise are the outputs",
    content: () => (
      <div class="accordion-custom-content">
        <p>
          We are displaying a screen after the experiment with 4 unique points
          to evaluate our results.
        </p>
        <p>
          If the accuracy is high enough, we keep the results. Otherwise, we
          search immediately for another unbiased participant.
        </p>
        <p>
          More information about the accuracy calculation process can be found{" "}
          <a
            href="https://intercom.help/loceye/articles/3001628-how-does-loceye-calculate-accuracy"
            target="_blank"
          >
            here
          </a>
          .
        </p>
      </div>
    ),
  },
  {
    title: "Who will test my designs?",
    content: () => (
      <div class="accordion-custom-content">
        <p>
          Either your people (friends, co-workers, etc.) or participants
          recruited by Loceye.
        </p>
        <p>
          You can invite your users or panel and share the experiment with them
          using a single URL. All they need to have is a computer with a
          web-camera embedded or not.
        </p>
        <p>
          We provide a network of participants from all around the world by
          using credible external panels. Their sessions will be unbiased and
          directed by pre-defined tasks by you. We hate laboratory tests in
          corrupted environments; that's why we embrace Eye Tracking from your
          place.
        </p>
        <p>
          Learn more about the credibility of our panel{" "}
          <a
            href="https://intercom.help/loceye/articles/3001765-how-credible-are-the-data-our-panel-is-giving-back"
            target="_blank"
          >
            here
          </a>
          .
        </p>
      </div>
    ),
  },
  {
    title: "Can you help me recruit people?",
    content: () => (
      <div class="accordion-custom-content">
        <p>
          Yes, you can invite your own people or hire more participants starting
          from <b>10$ each</b> with our platform.
        </p>
        <p>
          This feature is limited to a Pro account. Please refer to our{" "}
          <a href="/pricing">Pricing</a> page in order to learn more
          information.
        </p>
      </div>
    ),
  },
];

export default FAQS;
