import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import SectionLayout from "./SectionLayout";

import uploadIcon from "../assets/how-upload-designs.svg";
import remoteIcon from "../assets/how-remote.svg";
import resultsIcon from "../assets/how-instant-results.svg";

const HowItWorks = ({ isLanding }) => {
  const myProps = isLanding
    ? {
        hasLine: true,
        noPadding: true,
        sectionId: "features",
      }
    : {
        title: "Our Features",
        description:
          "We value your time and your efforts. Data-driven decisions and neuromarketing are here for you.",
      };

  return (
    <SectionLayout {...myProps} customStyle={{ padding: "50px 0 50px 0" }}>
      <div className="container">
        <div className="row">
          <div className="offset-xs-1 col-xs-10 offset-sm-1 col-sm-10 offset-md-0 col-md-4 ">
            <div
              className="feature feature-3 boxed boxed--lg boxed--border text-center my-shadow"
              style={{ zIndex: "101", minHeight: "100%" }}
            >
              <img src={uploadIcon} style={{ width: "30%" }} />
              <h4 style={{ marginBottom: "1.5rem" }}>Upload your Designs</h4>
              <p style={{ color: "#555" }}>
                Upload your digital assets onto the Loceye platform where they
                will be stored safely in the cloud. Import from any tool via
                PNG/JPG {/* (Zeplin/ InDesign/ Photoshop/ Illustrator){" "} */}
              </p>
              <Link to="/how">Learn More</Link>
            </div>
            {/*end feature*/}
          </div>
          <div className="offset-xs-1 col-xs-10 offset-sm-1 col-sm-10 offset-md-0 col-md-4 ">
            <div
              className="feature feature-3 boxed boxed--lg boxed--border text-center my-shadow"
              style={{ zIndex: "101", minHeight: "100%" }}
            >
              <img src={remoteIcon} style={{ width: "30%" }} />
              <h4 style={{ marginBottom: "1.5rem" }}>Remote Recruiting</h4>
              <p style={{ color: "#555" }}>
                Stop worrying about recruiting. Loceye will share your content
                with real people we have recruited for your experiment based on
                your requested demographics.
              </p>
              <Link to="/how">Learn More</Link>
            </div>
            {/*end feature*/}
          </div>
          <div className="offset-xs-1 col-xs-10 offset-sm-1 col-sm-10 offset-md-0 col-md-4 ">
            <div
              className="feature feature-3 boxed boxed--lg boxed--border text-center my-shadow"
              style={{ zIndex: "101", minHeight: "100%" }}
            >
              <img src={resultsIcon} style={{ width: "30%" }} />
              <h4 style={{ marginBottom: "1.5rem" }}>Instant Results</h4>
              <p style={{ color: "#555" }}>
                Get your results in less than a day. We generate a custom report
                with Heat Maps and more visualisations, to enrich your
                presentation and validate your design assumptions.{" "}
              </p>
              <Link to="/how">Learn More</Link>
            </div>
            {/*end feature*/}
          </div>
        </div>
      </div>
    </SectionLayout>
  );
};

export default HowItWorks;
