import React, { Component, Fragment } from "react";
import Img from "gatsby-image";
import styled from "styled-components";

const StyledH1 = styled.h1`
  font-weight: bold;
  margin-bottom: 2rem;
`;

class Partners extends Component {
  render() {
    const { logos } = this.props;

    return (
      <section className="text-center">
        <div className="container" style={{ marginBottom: "2em" }}>
          <div className="row">
            <div className="col-md-12">
              <StyledH1>
                Trusted by world-class CRO, UX and AdTech teams at:
              </StyledH1>
              <p className="lead">
                Working with the industry's best and brightest minds to deliver
                outstanding results.
              </p>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <ul className="list-inline list-inline--images">
                <li>
                  <Img
                    fixed={logos[0]}
                    style={{
                      minWidth: 150,
                      width: "100%",
                      padding: "1em",
                      height: "100%",
                      minHeight: "1px",
                      filter: "opacity(0.7)",
                    }}
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

//make this component available to the app
export default Partners;
