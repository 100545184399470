import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import Blob from "./Blob";

const DiagonalImage = styled.img`
  max-height: 100%;
`;

const Body = styled.p`
  font-size: 1rem;
  line-height: 1.8;
  color: #444;
`;

const Container = styled.div`
  ${"" /* margin-bottom: 10rem; */}
`;

const DiagonalLayout = ({ images }) => {
  return (
    <Container>
      <section className="imageblock switchable switchable--switch feature-large space--sm">
        <div className="imageblock__content col-lg-6 col-md-6 pos-right diagonal_images">
          <DiagonalImage src={images[0].src} alt="Experiment Netflix" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-6 mt--2">
              <Blob seed={1} />

              <h3>
                <b>
                  A user-friendly platform to accurately measure Visual
                  Engagement
                </b>
              </h3>
              <Body>
                Loceye is a biometric eye tracking platform, which enables your
                team to design with confidence, optimize digital assets like
                website content, images, emails, ad creatives, and more to help
                you achieve the highest visual and emotional impact.
              </Body>
            </div>
          </div>
          {/*end of row*/}
        </div>
        {/*end of container*/}
      </section>

      <section className="imageblock switchable feature-large space--sm">
        <div className="imageblock__content col-lg-6 col-md-6 pos-right diagonal_images">
          <DiagonalImage src={images[1].src} alt="Experiment Netflix" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-6">
              <div style={{ backgroundColor: "transparent" }}>
                <Blob seed={2} />
                <h3>
                  <b>Valuable, Fast and Accurate Data</b>
                </h3>
                <Body>
                  We encourage marketers, agencies, market researchers, web
                  designers, UX experts and any other professionals who are
                  engaged in media creation to get real world, pre-launch
                  feedback about how users and customers see and engage with
                  their content.
                </Body>
              </div>
            </div>
          </div>
          {/*end of row*/}
        </div>
        {/*end of container*/}
      </section>

      <section className="imageblock switchable switchable--switch feature-large space--sm">
        <div className="imageblock__content col-lg-6 col-md-6 pos-right diagonal_images">
          <DiagonalImage src={images[2].src} alt="Experiment Netflix" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-6 mt--2">
              <Blob seed={0} />

              <h3>
                <b>Data Driven Design Decisions (4D)</b>
              </h3>
              <Body>
                We provide the proper data outcomes in order to eliminate
                guesswork and help you unlock Data Driven Design Decisions.
                <br className="hidden-xs hidden-sm" />
                <br className="hidden-xs hidden-sm" />
                Measure the visual engagement, understand the attention spots,
                make decisions that will increase the ROI, conversion rates and
                optimize the experience of your users.
              </Body>
            </div>
          </div>
          {/*end of row*/}
        </div>
      </section>
      {/*end of container*/}
    </Container>
  );
};

export default DiagonalLayout;
