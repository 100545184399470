import React from "react";
import { graphql } from "gatsby";

import Demo from "../components/Demo";
import DiagonalLayout from "../components/DiagonalLayout";
import Features from "../components/HowItWorks";
import Hero from "../components/Hero";
import Layout from "../components/Layout";
import Parners from "../components/Partners";
import SEO from "../components/seo";
import Banner from "../components/Banner";
import FAQ from "../components/FAQ/FAQ";
import CardCTA from "../components/CardCTA";
import VisualeyesCTA from "../components/VisualEyesRedirect";
import Cookies from "js-cookie";

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = { visualeyes_redirect: Cookies.get("visualeyes_redirect") };
  }

  handleCloseModal = () => {
    Cookies.set("visualeyes_redirect");
    this.setState({ visualeyes_redirect: Cookies.get("visualeyes_redirect") });
  };

  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;

    const images = [
      data.diagonal_1.childImageSharp.fluid,
      data.diagonal_2.childImageSharp.fluid,
      data.diagonal_3.childImageSharp.fluid,
    ];
    const logos = [data.tMobile.childImageSharp.fixed];

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Loceye | Understanding Humans"
          keywords={[
            `loceye`,
            `online eyetracking`,
            `marketing`,
            `ux`,
            `advertising`,
            `branding`,
            `eyetracking`,
          ]}
        />
        <Hero />
        <Features isLanding />
        <DiagonalLayout images={images} />
        <CardCTA />
        <Parners logos={logos} />
        {/* <Demo image={ data.demo.childImageSharp.fluid}/> */}
        <FAQ />
        {!this.state.visualeyes_redirect ? (
          <VisualeyesCTA close={this.handleCloseModal} />
        ) : null}
      </Layout>
    );
  }
}

export default Index;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    tMobile: file(relativePath: { regex: "/t-mobile.png/" }) {
      childImageSharp {
        fixed(
          width: 300
          quality: 100
          duotone: { highlight: "#aaaaaa", shadow: "#333333" }
        ) {
          srcSet
          base64
          src
        }
      }
    }
    demo: file(relativePath: { regex: "/success.jpg/" }) {
      childImageSharp {
        fluid(
          maxWidth: 1400
          quality: 80
          duotone: { highlight: "#64FFDA", shadow: "#333333" }
        ) {
          srcSet
          base64
          src
        }
      }
    }
    diagonal_1: file(relativePath: { regex: "/diagonal_1.png/" }) {
      childImageSharp {
        fluid(maxWidth: 900, quality: 80) {
          srcSet
          base64
          src
        }
      }
    }
    diagonal_2: file(relativePath: { regex: "/diagonal_2.png/" }) {
      childImageSharp {
        fluid(maxWidth: 900, quality: 80) {
          srcSet
          base64
          src
        }
      }
    }
    diagonal_3: file(relativePath: { regex: "/diagonal_3.png/" }) {
      childImageSharp {
        fluid(maxWidth: 900, quality: 80) {
          srcSet
          base64
          src
        }
      }
    }
  }
`;
