import React from 'react'
import PropTypes from 'prop-types';

import { gray } from '../utils/colors';

const Blob1 = ({
  fill,
  size,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 600 600"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M125.6,-91.4C170.5,-44,219.8,8.7,210.9,48.4C202.1,88.1,135.1,114.8,71.5,143.6C7.8,172.4,-52.5,203.3,-110.2,190.3C-167.8,177.3,-222.8,120.4,-221.6,67.3C-220.4,14.2,-163,-35,-116.7,-82.7C-70.4,-130.4,-35.2,-176.7,2.6,-178.8C40.4,-180.9,80.8,-138.8,125.6,-91.4Z"
        fill={fill}
        transform="translate(300 300)"
      />
    </svg>
  )
}

Blob1.propTypes = {
  fill: PropTypes.string,
  size: PropTypes.number,
};

Blob1.defaultProps = {
  fill: gray,
  size: 300,
};

export default Blob1
