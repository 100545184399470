import React, { Fragment, Component } from "react";
import styled from "styled-components";
import Confetti from "react-dom-confetti";

import Blob1 from "./Blob1";
import Blob2 from "./Blob2";
import FlatIllustration from "./FlatIllustration";

const Wrapper = styled.div`
  height: 100vh;

  display: flex;
  flex-direction: row;
  justify-content: center;

  z-index: 0;

  @media (max-width: 1024px) {
    width: 100%;
    flex-direction: column;
    height: auto;
    margin-bottom: 100px;
  }
`;

const FlatWrapper = styled.div`
  width: 45%;
  z-index: 2;
  display: block;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 5rem;

  svg {
    max-width: 60%;
  }

  @media (max-width: 768px) {
    justify-content: center;
    margin-right: 0;
    width: 100%;
    padding: 0em 5em;
    svg {
      height: 100%;
      max-width: 100%;
    }
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    justify-content: center;
    margin-right: 0;
    width: 100%;
    ${"" /* padding: 0 15em; */}
    svg {
      height: 100%;
      max-width: 100%;
    }
  }
`;

const TextWrapper = styled.div`
  width: calc(50%);
  height: 100%;
  z-index: 2;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;

  padding-right: 10em;

  @media (max-width: 768px) {
    width: 100%;
    align-items: center;
    padding: 1em 32px 10rem 32px;
    text-align: center;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    width: 100%;
    align-items: center;
    padding: 1em 15em 10rem 15em;
    text-align: center;
  }
`;

const BlobWrapper = styled.span`
  position: absolute;
  right: ${(props) => props.right}%;
  top: ${(props) => props.top}%;
  left: ${(props) => props.left}%;
  bottom: ${(props) => props.bottom}%;
  z-index: -1000;
  @media (max-width: 1024px) {
    top: -10000%;
  }
`;

// Confetti configuration
const config = {
  angle: "100",
  spread: "180",
  startVelocity: "58",
  elementCount: "110",
  dragFriction: 0.1,
  duration: "2440",
  stagger: 0,
  width: "8px",
  height: "8px",
  colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
};

const StyledH1 = styled.h1`
  font-weight: normal;
  font-size: 2.441em;
  text-align: left;
`;

const ButtonBlock = styled.div`
  display: block;
  text-align: center;
  margin-top: 15px;
`;

class Hero extends Component {
  state = {
    count: false,
    text: "",
    isDeleting: false,
    loopNum: 0,
    typingSpeed: 150,
    dataText: ["CRO optimization", "Online Advertising", "Package Designing"],
  };
  componentDidMount() {
    // TODO: uncomment this to enable confetti js
    // setTimeout(() => this.setState({ count: true }), 1500);
    const TypewriterTexts = [];
    this.handleType();
  }

  handleType = () => {
    const { isDeleting, loopNum, text, typingSpeed, dataText } = this.state;
    const i = loopNum % dataText.length;
    const fullText = dataText[i];

    this.setState({
      text: isDeleting
        ? fullText.substring(0, text.length - 1)
        : fullText.substring(0, text.length + 1),
      typingSpeed: isDeleting ? 30 : 150,
    });
    if (!isDeleting && text === fullText) {
      setTimeout(() => this.setState({ isDeleting: true }), 500);
    } else if (isDeleting && text === "") {
      this.setState({
        isDeleting: false,
        loopNum: loopNum + 1,
      });
    }
    setTimeout(this.handleType, typingSpeed);
  };

  render() {
    const { count, text } = this.state;
    return (
      <Wrapper>
        <FlatWrapper>
          <FlatIllustration />
        </FlatWrapper>
        <TextWrapper>
          <StyledH1>
            <div className="">
              <strong>Eye-Tracking</strong> for
              <br />
              {text}
            </div>
          </StyledH1>
          <Confetti
            active={count}
            config={config}
            width="100%"
            style={{ width: "100vw" }}
          />
          <ButtonBlock>
            <a
              className="btn btn--sm btn--primary type--uppercase"
              href="https://app.loceye.io/auth/signup"
              style={{ marginBottom: "5px" }}
            >
              <span className="btn__text" id="getstartednow">Get Started Now</span>
            </a>
            <p style={{ fontSize: "0.8rem", marginTop: ".5rem" }}>
              * No credit card required
            </p>
          </ButtonBlock>
        </TextWrapper>
        {/* <BlobWrapper right={25} top={30}>
          <Blob1 />
        </BlobWrapper>
        <BlobWrapper left={4} top={20}>
          <Blob2 size={350} />
        </BlobWrapper> */}
      </Wrapper>
    );
  }
}

export default Hero;
