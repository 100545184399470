import React, { Fragment } from "react";
import { Paragraph as P, H5 } from "../Typography";
import { accent, primary } from "../../utils/colors";
import { Accordion, Icon } from "semantic-ui-react";
import Collapsible from "react-collapsible";
import { Wrapper } from "./style";
import FAQs from "./FAQs";

import styled from "styled-components";

const StyledH1 = styled.h1`
  font-weight: bold;
  margin-bottom: 2rem;
`;

const FaqItem = ({ title, children, index }) => (
  <Wrapper>
    <Collapsible
      className="faq"
      openedClassName="faq active"
      triggerClassName="faq-title"
      triggerOpenedClassName="faq-title active"
      triggerTagName="button"
      contentInnerClassName="faq-content"
      trigger={title}
      transitionTime={300}
      easing="ease-in"
    >
      {children}
    </Collapsible>
  </Wrapper>
);

const FAQ = () => (
  <section id="#faq" className="text-center bg--secondary">
    <div className="container" style={{ marginBottom: "3em" }}>
      <div className="row">
        <div className="col-md-12">
          <StyledH1>Frequently Asked Questions</StyledH1>
        </div>
      </div>
    </div>
    <div class="col-md-6 offset-md-3 accordion-custom">
      {FAQs.map(({ title, content, index }) => (
        <FaqItem title={title} key={title} index={index}>
          {content()}
        </FaqItem>
      ))}
    </div>
  </section>
);
export default FAQ;
