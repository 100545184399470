import styled from 'styled-components';
import {
  black,
  blackNo2,
} from '../utils/colors';

export const Paragraph = styled.p`
  color: blackNo2;
  font-size: 0.8em;
`;

export const H5 = styled.h5`
  color: black;
  margin-bottom: 1em !important;
`;