import React from 'react'
import PropTypes from 'prop-types'

import { gray } from '../utils/colors'

const Blob2 = ({ fill, size }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 600 600"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M110.6,-175.9C141.5,-152.2,163.5,-118.5,179.3,-82.4C195,-46.2,204.4,-7.8,204.4,33.2C204.5,74.2,195.2,117.6,168.4,142.6C141.6,167.5,97.3,174,55.7,183.2C14.1,192.5,-24.8,204.7,-70.3,206.4C-115.8,208.1,-167.8,199.3,-195,168.6C-222.2,137.9,-224.4,85.2,-215.8,40.7C-207.2,-3.9,-187.7,-40.4,-176.6,-87.3C-165.5,-134.2,-162.8,-191.6,-134.7,-216.6C-106.7,-241.7,-53.3,-234.3,-6.8,-223.8C39.8,-213.3,79.7,-199.6,110.6,-175.9Z"
        fill={fill}
        transform="translate(300 300)"
      />
    </svg>
  )
}

Blob2.propTypes = {
  fill: PropTypes.string,
  size: PropTypes.number,
}

Blob2.defaultProps = {
  fill: gray,
  size: 300,
}

export default Blob2
